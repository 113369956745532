import React, { useEffect } from 'react'
import { useState } from 'react'

//components
import CustomDefaultSelect from 'components/selectInputs/CustomDefaultSelect'

//libraries
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { useForm } from 'react-hook-form'
import moment from 'moment'

// icons
import calendar from 'assets/icons/calendar1.svg'
import stroke from 'assets/icons/stroke-down.svg'
import PrimaryButtons from 'components/buttons/PrimaryButtons'
import { connect, useSelector } from 'react-redux'
import { getOpsAdmins } from 'helpers/getOpsAdmins'
import { getAdmins } from 'store/actions'

const ReportFilter = (props: any): JSX.Element => {
  const {
    handleFilter,
    filterDisplay,
    exportMain,
    setExportMain,
    importMain,
    setImportMain,
    setFilterDisplay,
    loading,
    onTrack,
    setOnTrack,
    delayed,
    setDelayed,
    admins: remoteAdmins,
    getAdmins,
  } = props

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm()

  const [shipmentType, setShipmentType] = useState('')
  const [shipmentTransportType, setShipmentTransportType] = useState('')

  let local_admin = useSelector((state: any) => state.dashboard.admins)
  const [admins, setAdmins] = useState([])
  // console.log('admins>>>>>>>>>>', admins)

  useEffect(() => {
    if (local_admin?.length > 0) {
      setAdmins(getOpsAdmins(local_admin, true))
    } else {
      setAdmins(getOpsAdmins(remoteAdmins, true))
    }
  }, [local_admin, remoteAdmins])

  useEffect(() => {
    getAdmins()
  }, [])

  const onSubmit = (data: any) => {
    setFilterDisplay(false)
    handleFilter({
      shipment_transport_type: data.shipment_transport_type.value ? data.shipment_transport_type.value : '',
      shipment_type: shipmentType,
      assignee_id: data.assignee.value ? data.assignee.value : '',
      is_delayed: delayed,
    })
  }

  return (
    // {/* filtered page **********************/}
    <form onSubmit={handleSubmit(onSubmit)} className={filterDisplay ? 'bg-[white] w-full z-50 shadow-xl' : 'hidden'}>
      {/* scroll */}
      <div className='py-6 rounded px-5 overflow-y-auto '>
        {/* head */}
        <div className='flex items-center justify-between'>
          <p className='text-base black-text-3'>Filter</p>

          <div className='cursor-pointer'>
            <p
              className='text-sm font-medium text-[#8A0000]'
              onClick={() => {
                console.log('what is showing filter ')
                handleFilter({
                  shipment_type: '',
                  shipment_transport_type: '',
                  assignee_id: '',
                  is_delayed: '',
                })
                setExportMain(false)
                setImportMain(false)
                setOnTrack(false)
                setDelayed(false)

                setFilterDisplay(false)

                reset()
              }}
            >
              Clear filter
            </p>
          </div>
        </div>

        {/* line */}
        <div className='w-full h-[1px] my-4 bg-[#F3F4F6]'></div>

        {/* shipment type */}
        <div>
          <p className='black-text-3 text-sm mb-[16px]'>Shipment Type</p>
          <div className='flex items-center gap-x-[8px]'>
            <div
              className={
                exportMain ? 'bg-[#1F2937]  rounded-3xl py-1 px-2 cursor-pointer' : 'border border-[#D1D5DB] rounded-3xl py-1 px-2 cursor-pointer'
              }
              onClick={() => {
                setExportMain(true)
                setImportMain(false)
                setShipmentType('export')
              }}
            >
              <p className={exportMain ? 'text-xs text-[white]' : 'text-xs text-[#6B7280]'}>Export</p>
            </div>

            <div
              className={
                importMain ? 'bg-[#1F2937]  rounded-3xl py-1 px-2 cursor-pointer' : 'border border-[#D1D5DB] rounded-3xl py-1 px-2 cursor-pointer'
              }
              onClick={() => {
                setImportMain(true)
                setExportMain(false)
                setShipmentType('import')
              }}
            >
              <p className={importMain ? 'text-xs text-[white]' : 'text-xs text-[#6B7280]'}>Import</p>
            </div>
          </div>
        </div>

        {/* Activity status */}
        <div className='mt-4'>
          <p className='black-text-3 text-sm mb-[16px]'>Activity Status</p>
          <div className='flex items-center gap-x-[8px]'>
            <div
              className={
                onTrack ? 'bg-[#1F2937]  rounded-3xl py-1 px-2 cursor-pointer' : 'border border-[#D1D5DB] rounded-3xl py-1 px-2 cursor-pointer'
              }
              onClick={() => {
                setOnTrack(true)
                setDelayed(false)
              }}
            >
              <p className={onTrack ? 'text-xs text-[white]' : 'text-xs text-[#6B7280]'}>On Track</p>
            </div>

            <div
              className={
                delayed ? 'bg-[#1F2937]  rounded-3xl py-1 px-2 cursor-pointer' : 'border border-[#D1D5DB] rounded-3xl py-1 px-2 cursor-pointer'
              }
              onClick={() => {
                setDelayed(true)
                setOnTrack(false)
              }}
            >
              <p className={delayed ? 'text-xs text-[white]' : 'text-xs text-[#6B7280]'}>Delayed</p>
            </div>
          </div>
        </div>

        {/* Transport type */}

        {/* line */}
        <div className='w-full h-[1px] my-4 bg-[#F3F4F6]'></div>

        <div className='w-full '>
          <p className='black-text-3 text-sm'>Service Type</p>
          {/* select */}
          <div className='mt-[-16px]'>
            <CustomDefaultSelect
              control={control}
              name={'shipment_transport_type'}
              id={'shipment_transport_type'}
              label={''}
              placeholder={'Select Service type'}
              isRequired={false}
              errors={errors}
              isDisabled={false}
              defaultValue={''}
              options={[
                { label: 'Ocean Freight', value: 'ocean-freight' },
                { label: 'Haulage', value: 'haulage' },
                { label: 'Customs Brokerage', value: 'customs_brokerage' },
              ]}
              icon=''
            />
          </div>
        </div>

        <div className='w-full '>
          <p className='black-text-3 text-sm'>Shipment Assignee</p>
          {/* select */}
          <div className='mt-[-16px]'>
            <CustomDefaultSelect
              control={control}
              name={'assignee'}
              id={'assignee'}
              label={''}
              placeholder={'Select Assignee'}
              isRequired={false}
              errors={errors}
              isDisabled={false}
              defaultValue={''}
              options={admins}
              icon=''
            />
          </div>
        </div>

        {/* line */}
        <div className='w-full h-[1px] my-4 bg-[#F3F4F6]'></div>

        {/* cancel and apply filter button */}
        <div className='flex item-center gap-x-[24px] mt-[48px]'>
          <div className='bg-[#F9FAFB] py-[10px] px-[14px] rounded cursor-pointer' onClick={() => setFilterDisplay(false)}>
            <p className='text-sm text-[#139C33]'>Cancel</p>
          </div>
          <PrimaryButtons title='Apply filter' style={{}} disabled={false} loading={loading} icon={''} />
        </div>
      </div>
    </form>
  )
}

const mapStateToProps = (state: any) => {
  const { error, loading_admins, admins } = state.dashboard

  const { edit_loading } = state.workflows

  return { error, loading_admins, admins, edit_loading }
}

export default connect(mapStateToProps, { getAdmins })(ReportFilter)
