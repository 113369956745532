import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Admin } from 'types/dashboard/Dashboard'
import { admin_role } from 'types/dashboard/Dashboard'

//icons
import caretdown from 'assets/icons/caret-down.svg'
import blackcalendar from 'assets/icons/black-calendar.svg'

import { getOpsAdmins } from 'helpers/getOpsAdmins'

interface OpsMemberType {
  admins: Admin[]
  period: string
  setPeriod: React.Dispatch<React.SetStateAction<string>>
  selectedOpsMember: {
    firstname: string
    lastname: string
    id: string
  }
  setSelectedOpsMember: React.Dispatch<
    React.SetStateAction<{
      firstname: string
      lastname: string
      id: string
    }>
  >
  totalTask?: number
}

const OpsMember = ({ admins, selectedOpsMember, setSelectedOpsMember, period, setPeriod, totalTask }: OpsMemberType) => {
  let admin_data: Admin = useSelector((state: any) => state.auth.admin_data)
  let local_admin: Admin = localStorage.getItem('admin_data') ? JSON.parse(localStorage.getItem('admin_data')!) : null
  admin_data = admin_data ? admin_data : local_admin

  // console.log('ops_member_admin_data>>>', admin_data)

  let opsAdmin: { firstname: string; lastname: string; id: string }[] = getOpsAdmins(admins)

  const [showPeriod, setShowPeriod] = useState<boolean>(false)

  const [showOpsMember, setShowOpsMember] = useState<boolean>(false)

  const options: string[] = ['all time', 'today', 'this week', 'this month', 'this year']

  const periodref = useRef<HTMLDivElement>(null)
  const opsref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (showPeriod && periodref.current && !periodref.current.contains(e.target)) {
        setShowPeriod(false)
      }
      if (showOpsMember && opsref.current && !opsref.current.contains(e.target)) {
        setShowOpsMember(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [showPeriod, showOpsMember])

  const firstName = selectedOpsMember?.firstname
  const taskLabel = firstName !== 'my tasks' ? `${firstName}'s tasks` : 'My Tasks'

  return (
    <div className={`flex items-center gap-x-4 mt-[18px] ${totalTask === 0 ? 'mb-6' : 'mb-8'}`}>
      {admin_role[admin_data.role] === admin_role['super_admin'] ? (
        <div className='relative' ref={opsref}>
          <div
            className='flex items-center gap-x-2 w-fit px-3 py-2 rounded-lg cursor-pointer solid-br bg-[#F9FAFB]'
            onClick={() => setShowOpsMember(!showOpsMember)}
          >
            <div className='text-[10px] text-white flex justify-center items-center h-[20px] w-[20px] rounded-full bg-[#286A3A] uppercase'>
              {selectedOpsMember?.firstname?.charAt(0)}
            </div>
            <p className='text-black font-light text-sm capitalize'>{taskLabel}</p>
            <img src={caretdown} alt='caret' />
          </div>
          {showOpsMember && (
            <div className='absolute w-[180px] break-words p-1 text-sm black-text-3 bg-[#F9FAFB] shadow-[1px_4px_12px_-1px_rgba(44,78,39,0.15)] rounded z-30 h-[300px] overflow-y-auto'>
              {[{ firstname: 'everyone', lastname: '', id: '' }, { firstname: 'my tasks', lastname: '', id: admin_data?._id }, ...opsAdmin].map(
                (option) => (
                  <p
                    className={`p-2 cursor-pointer capitalize ${
                      selectedOpsMember?.id === option?.id ? 'bg-green-100 text-[#139C33]' : 'text-black hover:bg-slate-100'
                    } font-light rounded`}
                    onClick={() => {
                      console.log('_member_selected', option)
                      setSelectedOpsMember(option)
                      setShowOpsMember(false)
                    }}
                  >
                    {option.firstname + ' ' + option.lastname}
                  </p>
                )
              )}
            </div>
          )}
        </div>
      ) : (
        <div className='flex items-center gap-x-2 bg-[#F9FAFB] w-fit px-3 py-2 rounded-lg'>
          <div className='text-[10px] text-white flex justify-center items-center h-[20px] w-[20px] rounded-full bg-[#286A3A] uppercase font-medium'>
            {admin_data?.firstname?.charAt(0)}
          </div>
          <p className='text-black font-light text-sm capitalize'>{admin_data?.firstname}</p>
        </div>
      )}

      <div className='relative' ref={periodref}>
        <div
          className='flex items-center gap-x-2 bg-[#F9FAFB] w-fit px-3 py-2 cursor-pointer rounded-lg solid-br'
          onClick={() => setShowPeriod(!showPeriod)}
        >
          <img src={blackcalendar} alt='calendar' />
          <p className='text-black font-light text-sm capitalize'>{period}</p>
          <img src={caretdown} alt='caret' />
        </div>
        {showPeriod && (
          <div className='absolute w-[125px] z-50 p-1 text-sm black-text-3 bg-[#F9FAFB] shadow-[1px_4px_12px_-1px_rgba(44,78,39,0.15)] rounded'>
            {options.map((option) => (
              <p
                className={`p-2 cursor-pointer capitalize ${
                  period === option ? 'bg-green-100 text-[#139C33]' : 'text-black hover:bg-slate-100'
                } font-light rounded`}
                onClick={() => {
                  setPeriod(option)
                  setShowPeriod(false)
                }}
              >
                {option}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default OpsMember
